const pkgJson = require('../package.json')

module.exports = {
  env: 'production',
  url: 'https://togethrust.com',
  server: {
    port: 3000
  },
  mongodb: {
    url: 'mongodb+srv://tgth:aTzfameH8H4PoTN4@cluster0.iobub.mongodb.net/tgth?retryWrites=true&w=majority'
  },
  morgan: {
    logPath: '/home/freddy/www/logs/tgth-ssr/access.log'
  },

  facebook: {
    appId: '197102964018102',
    clientToken: 'f316e709915225da492d4279509b30f8',
    appSecret: '35ba30eb345daf949364c99be672b8b4',
    callbackUrl: 'https://togethrust.com/api/auth/facebook/callback',
    profileFields: ['id', 'emails', 'name', 'picture.type(large)'],
    scope: ['public_profile', 'email']
  },

  google: {
    appId:
      '599536367336-0stnipg9sqc4i7tlt8i4ueptin36ecu5.apps.googleusercontent.com',
    appSecret: 'WSe79JurPwGH2QQSDNNd0dUe',
    callbackUrl: 'https://togethrust.com/api/auth/google/callback',
    scope: ['profile', 'email'],
    tagManagerId: 'GTM-MK6WQF6'
  },

  googleAnalytics: {
    id: 'UA-214313493-1',
    config: {
      //debug: true,
      titleCase: false,
      gaOptions: {
        alwaysSendReferrer: true
        //cookieDomain: 'togethrust.com'
      },
      name: 'tgth-prod'
    }
  },

  session: {
    secret: 'c*SGzmYDjq95#Xau!@P4^d8?*',
    key: 'sessionId',
    resave: false,
    saveUninitialized: false,
    rolling: true,
    proxy: true,
    cookie: {
      //maxAge: 31 * 24 * 60 * 60 * 1000,
      maxAge: 15 * 60 * 1000,
      // FIXME: WHY DOEST IT BREAK SETCOOKIE ???
      //secure: true,
      sameSite: 'Strict',
      httpOnly: true
    }
  },

  loggly: {
    logglyKey: 'eb1f7a05-1d92-4b5b-94ad-fbe594ff523b',
    sendConsoleErrors: false,
    tag: 'front-prod',
    useUtfEncoding: true
  },

  winston: {
    File: {
      level: 'warn',
      filename: '/home/freddy/www/logs/tgth-ssr/app.log',
      handleExceptions: true,
      json: true,
      maxsize: 5242880, // 5MB
      maxFiles: 5,
      colorize: false
    },
    Console: {
      level: 'info',
      handleExceptions: true,
      json: false,
      colorize: true
    },
    Loggly: {
      token: '43586f73-28e3-4c42-aa4e-f26d10a6f2ca',
      subdomain: 'togethrust',
      tags: ['backend-prod'],
      json: true
    },
    MongoDB: {
      level: 'info',
      db: 'mongodb+srv://tgth:aTzfameH8H4PoTN4@cluster0.iobub.mongodb.net/tgth?retryWrites=true&w=majority',
      decolorize: true,
      metaKey: 'data',
      expireAfterSeconds: 3 * 31 * 24 * 60 * 60
    }
  },

  auth: {
    homePath: '/home',
    logoutSuccessPath: '/home?logoutSuccess=true',
    loginPath: '/home?showLogin=true',
    registerPath: '/home?showRegister=true',
    successEmailChangedPath: '/profile?emailChanged=true',
    failureEmailChangedPath: '/home?invalidCode=true',
    successLoginPath: '/dashboard?isLogged=true',
    invalidVerificationCodePath: '/home?invalidCode=true',
    accountCreationErrorPath: '/home?accountCreationError=true'
  },

  sendgrid: {
    apiKey:
      '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',
    emailVerificationCode: 'd-e621275791b845a5bf3f89e28e23f02b',
    temporaryEmailVerificationCode: 'd-468d15b5e48c44d796f26ed357685a20',
    welcomeEmail: 'd-b04e0b660f0546caae677cb5d446c5f6',
    completeYourProfileEmail: 'd-776a4dde4f6b40298231b4ccb7f3ee64',
    forgottenPassword: 'd-d91eb6160a164b3db8931bcb3385ff6c',
    piggyInvitationNewUser: 'd-8831a4f425814b72a3a12a625510eccf',
    piggyInvitationExistingUser: 'd-f1c36659642e4e52b27b1b93bf376b57',
    documentStatusChange: 'd-1f33685a378d445caf66ff10ecc6e47f',
    accountStatusChange: 'd-e0356391eabb44e4af96195857afaee2',
    piggyInvitationReminder: 'd-fa91936971174d568d231768c6ed1a92',
    piggyStarted: 'd-f93834164fa046bea12abda95cfc3ad6',
    piggyNextCycle: 'd-2fc1c722deb7436db5d13fa7065cd98c',
    piggyCompleted: 'd-def390fdbff946dc90a978f3ad667b31',
    piggyContributionReminder: 'd-5f84972da6364a4092b34eb5d271e041',
    piggyCancellationByUser: 'd-48fab8679c964e26a6910c0ce6b16f2d',
    piggyExpirationByWorker: 'd-0dbf431e6bff4b8fb0ddebefa3ce531a',
    piggyStoppedByWorker: 'd-bffeaf5a2c5e49d7b5dbb6ad11dde6c1',
    userDebtAcknowledgement: 'd-374667a5c6a34a3cad6cf8ed5af0671f',
    userDebtRefundAcknowledgement: 'd-fb9a4710b276414fb38dff9ab9c282f5',
    kycUpgradeRegulation: 'd-beba15dedc0c43e985860ea2462dd790',
    sender: 'no-reply@togethrust.com',
    contactUsRecipient: 'contact@togethrust.com',
    documentsValidationRecipient: 'contact@togethrust.com',
    verifyEmailPath: '/api/auth/verify-email?code=',
    verifyTemporaryEmailPath: '/api/auth/verify-temporary-email?code=',
    profileLink: 'https://togethrust.com/profile',
    documentLink: 'https://togethrust.com/profile#tab=1',
    dashboardLink: 'https://togethrust.com/dashboard',
    piggyDashboardLink: 'https://togethrust.com/piggy/dashboard/',
    piggyCreateLink: 'http://togethrust.com/create-piggy',
    createAccountLink: 'https://togethrust.com/home?showRegister=true'
  },

  googlePlace: {
    apiKey: 'AIzaSyA76Is7YqsQkS7M2AZoIMywF3vHScfsxfI'
  },

  form: {
    encoding: 'utf-8',
    uploadDir: '/tmp',
    profilePicDirBackup: '/tmp/tgth-ssr/profilePictures/',
    profilePicDir: '/home/freddy/www/tgth-ssr/public/static/profilePictures/',
    documentsDirBackup: '/tmp/tgth-ssr/documents/',
    documentsDir: '/home/freddy/www/tgth-ssr/public/static/documents/',
    keepExtensions: true,
    maxFileSize: 20 * 1024 * 1024
  },

  sentry: {
    browser: {
      dsn: 'https://b135db1ce3f14fc5b856fdcb963cf1cf@o214568.ingest.sentry.io/5795833',
      debug: true,
      maxBreadcrumbs: 50,
      release: pkgJson.version,
      attachStacktrace: true,
      sendDefaultPii: true
    },
    server: {
      dsn: 'https://44f190b8ca2148f19d289c0aebdf924b@o214568.ingest.sentry.io/5795838',
      debug: true,
      maxBreadcrumbs: 50,
      release: pkgJson.version,
      attachStacktrace: true,
      sendDefaultPii: true
    }
  },
  psp: {
    api_access_key: 'Dris4RynMQgSAzT527TG1pCghE31tlnE',
    api_secret_key: 'DqYGaoRdwTKwif5S6T7F7FG1I8CP6mmF',
    api_url: 'https://emoney-services.w-ha.com/api',
    moneyInSuccessUrl: 'https://togethrust.com/dashboard?moneyinstatus=success',
    moneyInErrorUrl: 'https://togethrust.com/dashboard?moneyinstatus=error',
    feesWalletId: 'WF-6366669100335463'
  },
  bearer: {
    secretKey: 'app_7198a4705313c2a7dede4001af20a530912bc34a3cbe10ca7b',
    stripSensitiveData: false
  },
  workersIntervals: {
    piggyInvitationExpiration: '1 hours',
    piggyContributionExpiration: '1 hours',
    piggyDistribution: '1 hours',
    reminderJob: '1 hours'
  },
  hotjar: {
    appId: '2356113'
  },
  robots: {
    UserAgent: '*',
    Disallow: '/api',
    Sitemap: 'https://togethrust.com/sitemap.xml'
  }
}
