const deepmerge = require('deepmerge')

module.exports = {
  getConfig() {
    const env = process.env.NODE_ENV
    const serverEnv = process.env.SERVER_ENV
    const commonConf = require('../../config/common.js')
    const devConf = require('../../config/development.js')
    const qaConf = require('../../config/qa.js')
    const prodConf = require('../../config/production.js')
    let localConf = {}

    try {
      localConf = require('../../config/local.js')
    } catch (error) {}

    if (serverEnv === 'development') {
      return deepmerge.all([commonConf, devConf, localConf])
    }

    if (serverEnv === 'qa') {
      return deepmerge.all([commonConf, qaConf, localConf])
    }

    if (env === 'production') {
      return deepmerge.all([commonConf, prodConf, localConf])
    }

    return deepmerge.all([commonConf, devConf, localConf])
  }
}
