const pkgJson = require('../package.json')

module.exports = {
  env: 'qa',
  url: 'https://qa.togethrust.com',
  server: {
    port: 3000
  },
  mongodb: {
    url: 'mongodb+srv://tgth:ELhGhQWkUmAIhtjo@cluster0.mp8u5.mongodb.net/tgth?retryWrites=true&w=majority'
  },
  morgan: {
    logPath: '/home/freddy/www/logs/tgth-ssr/access.log'
  },

  facebook: {
    appId: '4080619628687958',
    clientToken: '0710ee1a732c4baa01b59b45580c3148',
    appSecret: '26457e9715cd96ef25297e3032972758',
    callbackUrl: 'https://qa.togethrust.com/api/auth/facebook/callback',
    profileFields: ['id', 'emails', 'name', 'picture.type(large)'],
    scope: ['public_profile', 'email']
  },

  google: {
    appId:
      '599536367336-ksfij6pboopudj3k2m7ucpdmfn6o1pu9.apps.googleusercontent.com',
    appSecret: '4McoxduDi3wJ3OrnpfQSfMPQ',
    callbackUrl: 'https://qa.togethrust.com/api/auth/google/callback',
    scope: ['profile', 'email'],
    tagManagerId: 'GTM-5KJMCWC'
  },

  googleAnalytics: {
    id: 'UA-214438072-1',
    config: {
      debug: true,
      titleCase: false,
      gaOptions: {
        alwaysSendReferrer: true
        //cookieDomain: 'qa.togethrust.com'
      },
      name: 'tgth-qa'
    }
  },

  session: {
    secret: '-@3mV2#54%B59HwHdzt&3@pya',
    key: 'sessionId',
    resave: false,
    rolling: true,
    saveUninitialized: false,
    proxy: true,
    cookie: {
      //maxAge: 31 * 24 * 60 * 60 * 1000,
      maxAge: 15 * 60 * 1000,
      // FIXME: WHY DOEST IT BREAK SETCOOKIE ???
      //secure: true,
      sameSite: 'Strict',
      httpOnly: true
    }
  },

  loggly: {
    logglyKey: '29504817-9e97-4475-9355-c6f4ebffff70',
    sendConsoleErrors: false,
    tag: 'front-qa',
    useUtfEncoding: true
  },

  winston: {
    File: {
      level: 'warn',
      filename: '/home/freddy/www/logs/tgth-ssr/app.log',
      handleExceptions: true,
      json: true,
      maxsize: 5242880, // 5MB
      maxFiles: 5,
      colorize: false
    },
    Console: {
      level: 'debug',
      handleExceptions: true,
      json: false,
      colorize: true
    },
    Loggly: {
      token: 'fe2b52a6-d1c5-4847-acf3-32ec8016bda1',
      subdomain: 'tgthdev',
      tags: ['backend-qa'],
      json: true
    },
    MongoDB: {
      level: 'debug',
      db: 'mongodb+srv://tgth:ELhGhQWkUmAIhtjo@cluster0.mp8u5.mongodb.net/tgth?retryWrites=true&w=majority',
      decolorize: true,
      metaKey: 'data',
      expireAfterSeconds: 3 * 31 * 24 * 60 * 60
    }
  },

  auth: {
    homePath: '/index',
    logoutSuccessPath: '/index?logoutSuccess=true',
    loginPath: '/index?showLogin=true',
    registerPath: '/index?showRegister=true',
    successEmailChangedPath: '/profile?emailChanged=true',
    failureEmailChangedPath: '/index?invalidCode=true',
    successLoginPath: '/dashboard?isLogged=true',
    invalidVerificationCodePath: '/index?invalidCode=true',
    accountCreationErrorPath: '/index?accountCreationError=true'
  },

  sendgrid: {
    apiKey:
      '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',
    emailVerificationCode: 'd-e621275791b845a5bf3f89e28e23f02b',
    temporaryEmailVerificationCode: 'd-468d15b5e48c44d796f26ed357685a20',
    welcomeEmail: 'd-b04e0b660f0546caae677cb5d446c5f6',
    completeYourProfileEmail: 'd-776a4dde4f6b40298231b4ccb7f3ee64',
    forgottenPassword: 'd-d91eb6160a164b3db8931bcb3385ff6c',
    piggyInvitationNewUser: 'd-8831a4f425814b72a3a12a625510eccf',
    piggyInvitationExistingUser: 'd-f1c36659642e4e52b27b1b93bf376b57',
    documentStatusChange: 'd-1f33685a378d445caf66ff10ecc6e47f',
    accountStatusChange: 'd-e0356391eabb44e4af96195857afaee2',
    piggyInvitationReminder: 'd-fa91936971174d568d231768c6ed1a92',
    piggyStarted: 'd-f93834164fa046bea12abda95cfc3ad6',
    piggyNextCycle: 'd-2fc1c722deb7436db5d13fa7065cd98c',
    piggyCompleted: 'd-def390fdbff946dc90a978f3ad667b31',
    piggyContributionReminder: 'd-5f84972da6364a4092b34eb5d271e041',
    piggyCancellationByUser: 'd-48fab8679c964e26a6910c0ce6b16f2d',
    piggyExpirationByWorker: 'd-0dbf431e6bff4b8fb0ddebefa3ce531a',
    piggyStoppedByWorker: 'd-bffeaf5a2c5e49d7b5dbb6ad11dde6c1',
    userDebtAcknowledgement: 'd-374667a5c6a34a3cad6cf8ed5af0671f',
    userDebtRefundAcknowledgement: 'd-fb9a4710b276414fb38dff9ab9c282f5',
    kycUpgradeRegulation: 'd-beba15dedc0c43e985860ea2462dd790',
    sender: 'tgthdev+qasender@gmail.com',
    contactUsRecipient: 'tgthdev+qasender@gmail.com',
    documentsValidationRecipient: 'tgthdev+qasender@gmail.com',
    verifyEmailPath: '/api/auth/verify-email?code=',
    verifyTemporaryEmailPath: '/api/auth/verify-temporary-email?code=',
    profileLink: 'https://qa.togethrust.com/profile',
    documentLink: 'https://qa.togethrust.com/profile#tab=1',
    dashboardLink: 'https://qa.togethrust.com/dashboard',
    piggyDashboardLink: 'https://qa.togethrust.com/piggy/dashboard/',
    piggyCreateLink: 'http://qa.togethrust.com/create-piggy',
    createAccountLink: 'https://qa.togethrust.com/index?showRegister=true'
  },

  googlePlace: {
    apiKey: 'AIzaSyClb_gTYtzJuwKHJpNSr7NRj9yavOhFhTg'
  },

  form: {
    encoding: 'utf-8',
    uploadDir: '/tmp',
    profilePicDirBackup: '/tmp/tgth-ssr/profilePictures/',
    profilePicDir: '/home/freddy/www/tgth-ssr/static/profilePictures/',
    documentsDirBackup: '/tmp/tgth-ssr/documents/',
    documentsDir: '/home/freddy/www/tgth-ssr/static/documents/',
    keepExtensions: true,
    maxFileSize: 20 * 1024 * 1024
  },

  sentry: {
    browser: {
      dsn: 'https://cbe7a58e62f947c883b96610082ce172@sentry.io/1353016',
      debug: true,
      maxBreadcrumbs: 50,
      release: pkgJson.version,
      attachStacktrace: true,
      sendDefaultPii: true
    },
    server: {
      dsn: 'https://fa0693d85f0747d195344b81118ea443@sentry.io/1353026',
      debug: true,
      maxBreadcrumbs: 50,
      release: pkgJson.version,
      attachStacktrace: true,
      sendDefaultPii: true
    }
  },
  psp: {
    api_access_key: 'JGPTC5FKFzyZxFK93N2r4rfFnkVI7UVb',
    api_secret_key: 'TxBcVMeqWIjq3eFicuxijeKqGAUhitRb',
    api_url: 'https://test-emoney-services.w-ha.com/api',
    moneyInSuccessUrl:
      'https://qa.togethrust.com/dashboard?moneyinstatus=success',
    moneyInErrorUrl: 'https://qa.togethrust.com/dashboard?moneyinstatus=error',
    feesWalletId: 'WF-9777858522023314'
  },
  bearer: {
    secretKey: 'app_7198a4705313c2a7dede4001af20a530912bc34a3cbe10ca7b',
    stripSensitiveData: false
  },
  workersIntervals: {
    piggyInvitationExpiration: '4 hours',
    piggyContributionExpiration: '4 hours',
    piggyDistribution: '4 hours',
    reminderJob: '1 hours'
  },
  hotjar: {
    appId: '3199970'
  }
}
