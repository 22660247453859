module.exports = {
  auth: {
    homePath: '/home',
    logoutSuccessPath: '/home?logoutSuccess=true',
    loginPath: '/home?showLogin=true',
    registerPath: '/home?showRegister=true',
    successEmailChangedPath: '/profile?emailChanged=true',
    failureEmailChangedPath: '/home?invalidCode=true',
    successLoginPath: '/dashboard?isLogged=true',
    invalidVerificationCodePath: '/home?invalidCode=true'
  },
  profile: {
    completeKycRequiredPath: '/profile?completeKycRequired=true',
    completeMandatoryProfileRequiredPath:
      '/profile?completeMandatoryProfileRequired=true',
    completeIdentityRequiredPath:
      '/profile?completeIdentityRequired=true#tab=1',
    completeIbanRequiredPath: '/profile?completeIbanRequired=true#tab=2',
    kyc3Required: '/dashboard?kyc3Required=true',
    minLegalAge: 18 //years
  },

  piggy: {
    minStartDateDelay: 7,
    minAmountPerUser: 10,
    minParticipants: 2,
    commission: 0.0
  },
  zendesk: {
    key: '186c5e42-adec-42a6-8c28-01cc7cf9ca1a',
    apiToken: 'dgu5ZoasXDycFIMU9TAU2wtliybixxQ7TAZJGEa6'
  },
  rateLimit: {
    windowMs: 15 * 60 * 1000, // 15 minutes
    max: 200
  },
  robots: {
    UserAgent: '*',
    Disallow: '/'
  }
}
