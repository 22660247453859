export const actionTypes = {
  TICK: 'TICK',
  INCREMENT: 'INCREMENT',
  DECREMENT: 'DECREMENT',
  RESET: 'RESET',
  SENDCONTACT: 'SENDCONTACT',
  SENDCONTACTFAILED: 'SENDCONTACTFAILED',
  SENDCONTACTSUCCEEDED: 'SENDCONTACTSUCCEEDED',
  FETCHCURRENTUSER: 'FETCHCURRENTUSER',
  FETCHCURRENTUSERSUCCEEDED: 'FETCHCURRENTSUCCEEDED',
  FETCHCURRENTUSERFAILED: 'FETCHCURRENTUSERFAILED',
  USERLOGOUT: 'USERLOGOUT',
  FETCHNOTIFICATION: 'FETCHNOTIFICATION',
  FETCHNOTIFICATIONSUCCEEDED: 'FETCHNOTIFICATIONEEDED',
  FETCHNOTIFICATIONFAILED: 'FETCHNOTIFICATIONFAILED',
  UPDATE_UNREAD_NOTIFICATION_COUNTER: 'UPDATE_UNREAD_NOTIFICATION_COUNTER'
}
