import {
  captureException,
  configureScope,
  withScope,
  captureMessage
} from '@sentry/browser'
import fetchPonyfill from 'fetch-ponyfill'
import Router from 'next/router'
import { ulid } from 'ulid'
import * as urlParser from 'url-parse'
import * as ConfigService from '../api/services/config'
import Cookie from 'js-cookie'
import Iban from 'iban'
const { fetch } = fetchPonyfill()
const appConfig = ConfigService.getConfig()
import ReactGA from 'react-ga'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(utc)
dayjs.extend(localizedFormat)

export const generateRandomId = ulid

export function capitalize(s) {
  if (s && s[0]) {
    return s[0].toUpperCase() + s.slice(1)
  }
  return s
}

export function redirect(target, ctx = {}) {
  if (ctx.res) {
    // server
    // 302: "See other"
    ctx.res.writeHead(302, { Location: target })
    ctx.res.end()
  } else {
    Router.push(target)
  }
}

export function removeFacebookLoginHash() {
  if (window && window.location.hash === '#_=_') {
    window.history && window.history.replaceState
      ? window.history.replaceState(
          null,
          null,
          window.location.href.split('#')[0]
        )
      : (window.location.hash = '')
  }
}

export function removeUrlQueryParameter(param, url, cb) {
  const parsedUrl = urlParser(url, true)
  const query = parsedUrl.query
  delete query[param]
  parsedUrl.set('query', query)

  if (window && window.history && window.history.replaceState) {
    window.history.replaceState(null, null, parsedUrl.href)
  }
}

export async function fetchCurrentUser(req, res) {
  if (req && req.user) {
    const removeSensitiveData = (user) => {
      if (user) {
        const clone = Object.assign({}, user)
        delete clone.emailVerificationCode
        delete clone.facebook
        delete clone.google
        delete clone.password
        delete clone.createdAt
        delete clone.updatedAt
        return clone
      }
    }

    // server side
    return { isAuthenticated: !!req.user, user: removeSensitiveData(req.user) }
  }

  try {
    const res = await (
      await fetch(`${appConfig.url}/api/auth/is-authenticated`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-tgth-trace-id': generateRandomId()
        },
        credentials: 'same-origin'
      })
    ).json()
    const { isAuthenticated, user } = res
    return { isAuthenticated, user }
  } catch (err) {
    console.error(
      `Service.isAuthenticated: failed - Error ${JSON.stringify(err)}`
    )
    return false
  }
}

export function getDisplayName(user) {
  if (!user) {
    return ''
  }

  return capitalize(user.firstname) + ' ' + capitalize(user.lastname)
}

export function isSecurePassword(password) {
  // return
  // /(?=.*[0-9])(?=.*[a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ])(?=.*[A-ZÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ]).{8,}/.test(password)

  return password && password.length > 7
}

export const ErrorLevel = {
  fatal: 'fatal',
  /** JSDoc */
  error: 'error',
  /** JSDoc */
  warning: 'warning',
  /** JSDoc */
  log: 'log',
  /** JSDoc */
  info: 'info',
  /** JSDoc */
  debug: 'debug',
  /** JSDoc */
  critical: 'critical'
}

export function reportError(error, ctx = null) {
  if (appConfig.env !== 'production') {
    console.error(JSON.stringify(error))
    //return
  }
  if (!ctx) captureException(error)
  else {
    configureScope((scope) => {
      if (error.message) {
        scope.setFingerprint([error.message])
      }

      if (error.statusCode) {
        scope.setExtra('statusCode', error.statusCode)
      }

      const { req, res, query, pathname } = ctx

      if (res && res.statusCode) {
        scope.setExtra('statusCode', res.statusCode)
      }

      if (typeof window !== 'undefined') {
        scope.setTag('ssr', false)
        scope.setExtra('query', query)
        scope.setExtra('pathname', pathname)

        // On client-side we use js-cookie package to fetch it
        const sessionId = Cookie.get('sessionId')
        if (sessionId) {
          scope.setUser({ sessionId: sessionId })
        }
      } else {
        scope.setTag('ssr', true)
        scope.setExtra('url', req.url)
        scope.setExtra('method', req.method)
        scope.setExtra('headers', req.headers)
        scope.setExtra('params', req.params)
        scope.setExtra('query', req.query)

        // On server-side we take session cookie directly from request
        if (req.user) {
          scope.setUser({ id: req.user.id })
          scope.setExtra('email', req.user.email)
        }
      }
    })

    captureException(error)
  }

  if (window && window._LTracker && _LTracker.push) {
    // loggly
    _LTracker.push(`error: ${JSON.stringify(error.message)}`)
  }
}

export function reportComponentDidCatch(error, errorInfo) {
  if (appConfig.env !== 'production') {
    console.error(JSON.stringify(error))
    //return
  }
  withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key])
    })
    captureException(error)
  })
  if (window && window._LTracker && _LTracker.push) {
    // loggly
    _LTracker.push(
      `componentDidCatch: ${JSON.stringify(message)} - ${JSON.stringify(
        errorInfo
      )}`
    )
  }
}

export function reportMessage(message, level = ErrorLevel.error) {
  if (appConfig.env !== 'production') {
    console.log(message)
    //return
  }
  captureMessage(message, level)
  if (window && window._LTracker && _LTracker.push) {
    // loggly
    _LTracker.push(`message: ${level} - ${JSON.stringify(message)}`)
  }
}

export function isParentElementOf(parent, child) {
  if (!parent || !child) {
    return false
  }
  let node = child.parentNode
  while (node !== null) {
    if (node === parent) {
      return true
    }
    node = node.parentNode
  }
  return false
}

export function deepClone(obj) {
  if (!obj) {
    return null
  }
  return JSON.parse(JSON.stringify(obj))
}

export function getQueryString(params) {
  return Object.keys(params)
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&')
}

export function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
}

export function getUrlParams(req) {
  if (req && (req.params || req.query)) return req.params || req.query
  return Router.query
}

export function occurencesInArrayOf(value, array) {
  return array.filter((item) => value === item).length
}

export const addressComponentKey = {
  STREET_NUMBER: 'street_number',
  ROAD: 'route',
  ZIP_CODE: 'postal_code',
  LOCALITY: 'locality',
  COUNTRY: 'country',
  STATE: 'administrative_area_level_1',
  DEPARTMENT: 'administrative_area_level_2',
  TOWN: 'sublocality_level_1',
  AREA: 'sublocality_level_2'
}

export function getKeyFromGooglePlaceAddressComponent(addressComponents, key) {
  let value = ''
  const component = addressComponents.filter((aComp) =>
    aComp.types.some((type) => type === key)
  )
  if (component != null && component.length > 0) value = component[0].long_name
  return value
}

export function isUserMandatoryProfileComplete(user) {
  return (
    user &&
    user.firstname &&
    user.lastname &&
    user.email &&
    user.birthDate &&
    user.country &&
    user.hasAcceptedCGU &&
    user.professionCategory &&
    user.income
  )
}

export function isUserMandatoryIdentityComplete(user) {
  return (
    user &&
    user.birthCity &&
    user.birthCountry &&
    user.nationality &&
    user.pspIdentityDocument1
  )
}

export function isNumber(value) {
  return !isNaN(parseFloat(value)) && isFinite(value)
}

export function isIbanInputValid(iban) {
  return Iban.isValid(iban)
}

export function normalizeIban(iban) {
  return iban.replace(/[^a-zA-Z0-9]/gi, '').toUpperCase()
}

export function getKyc3RequiredMessage() {
  return "La configuration de votre profil ne vous permet pas d'aller au-delà de 10 000€. Si vous souhaitez dépasser ce plafond, merci de fournir une seconde pièce d'identité et d'indiquer votre adresse postale"
}

export function trackCurrentPage() {
  try {
    if (window)
      ReactGA.pageview(window.location.pathname + window.location.search)
  } catch (error) {
    //fail silently
  }
}

export function trackCurrentModal(url) {
  try {
    ReactGA.modalview(url)
  } catch (error) {
    //fail silently
  }
}

export function isExpiredSessionError(error) {
  return error?.code === 401 || error?.status === 401
}

export function redirectOnExpiredSession(error) {
  let redirectPath = ''
  if (window && window?.location?.pathname) {
    redirectPath = `&sessionExpired=true&redirectPath=${window.location.pathname}`
  }
  return redirect(appConfig.auth.loginPath + redirectPath)
}

export function debounce(func, wait, immediate) {
  let timeout

  return function executedFunction() {
    let context = this
    let args = arguments
    let later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    let callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function throttle(func, limit) {
  let inThrottle
  return function () {
    const args = arguments
    const context = this
    if (!inThrottle) {
      func.apply(context, args)
      inThrottle = true
      setTimeout(() => (inThrottle = false), limit)
    }
  }
}

export function getFormattedDate(date, format = 'L') {
  return new dayjs(date).utc().format(format)
}
