import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { init, configureScope } from '@sentry/browser'
import { PageTransition } from 'next-page-transitions'
import App from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import TgLoader from '../components/tgLoader/tgLoader'
import { reportComponentDidCatch } from '../lib/utils'
import withReduxStore from '../lib/with-redux-store'
import 'react-toastify/dist/ReactToastify.css'
import 'react-calendar/dist/Calendar.css'
import * as ConfigService from '../api/services/config'
import Head from 'next/head'
import ReactGA from 'react-ga'
const appConfig = ConfigService.getConfig()

const TIMEOUT = 300
const loadingDelay = 0
class MyApp extends App {
  // static async getInitialProps({ Component, ctx }) {
  //   let pageProps = {}

  //   if (Component.getInitialProps) {
  //     pageProps = await Component.getInitialProps(ctx)
  //   }

  //   return { pageProps }
  // }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount() {
    init(appConfig.sentry.browser)
    configureScope((scope) => {
      scope.setTag('browser', 'true')
      scope.setTag('process.env', appConfig.env)
    })
    ReactGA.initialize(
      appConfig.googleAnalytics.id,
      appConfig.googleAnalytics.config
    )
  }

  componentDidCatch(error, errorInfo) {
    reportComponentDidCatch(error, errorInfo)
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props

    return (
      <>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="Togethrust landing page" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        </Head>
        <Provider store={reduxStore}>
          <PageTransition
            timeout={TIMEOUT}
            classNames="page-transition"
            loadingDelay={loadingDelay}
            loadingComponent={<TgLoader />}
            loadingTimeout={{
              enter: 100,
              exit: 0
            }}
            loadingClassNames="loading-indicator"
            skipInitialTransition
          >
            <Component {...pageProps} />
          </PageTransition>
        </Provider>
      </>
    )
  }
}

export default withReduxStore(MyApp)
