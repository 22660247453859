import { actionTypes } from './actionTypes'
import { initialState } from './initialState'

export function reducers(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TICK:
      return Object.assign({}, state, {
        lastUpdate: action.ts,
        light: !!action.light
      })
    case actionTypes.INCREMENT:
      return Object.assign({}, state, {
        count: state.count + 1
      })
    case actionTypes.DECREMENT:
      return Object.assign({}, state, {
        count: state.count - 1
      })
    case actionTypes.RESET:
      return Object.assign({}, state, {
        count: initialState.count
      })

    case actionTypes.SENDCONTACT:
      return Object.assign({}, state, {
        sendContactPending: true,
        sendContactSucceeded: false,
        sendContactFailed: false
      })
    case actionTypes.SENDCONTACTFAILED:
      return Object.assign({}, state, {
        sendContactPending: false,
        sendContactSucceeded: false,
        sendContactFailed: true
      })
    case actionTypes.SENDCONTACTSUCCEEDED:
      return Object.assign({}, state, {
        sendContactPending: false,
        sendContactSucceeded: true,
        sendContactFailed: false
      })
    case actionTypes.FETCHCURRENTUSER:
      return Object.assign({}, state, {
        fetchCurrentUserPending: true,
        fetchCurrentUserSucceeded: false,
        fetchCurrentUserFailed: false,
        user: null
      })
    case actionTypes.FETCHCURRENTUSERSUCCEEDED:
      return Object.assign({}, state, {
        fetchCurrentUserPending: false,
        fetchCurrentUserSucceeded: true,
        fetchCurrentUserFailed: false,
        user: action.payload.user
      })
    case actionTypes.FETCHCURRENTUSERFAILED:
      return Object.assign({}, state, {
        fetchCurrentUserPending: false,
        fetchCurrentUserSucceeded: false,
        fetchCurrentUserFailed: true,
        user: null
      })
    case actionTypes.USERLOGOUT:
      return Object.assign({}, state, {
        user: null,
        notifications: null,
        notificationUnreadCounter: 0
      })
    case actionTypes.FETCHNOTIFICATION:
      return Object.assign({}, state, {
        fetchNotificationPending: true,
        fetchNotificationSucceeded: false,
        fetchNotificationFailed: false,
        notifications: null,
        notificationUnreadCounter: 0
      })
    case actionTypes.FETCHNOTIFICATIONSUCCEEDED:
      return Object.assign({}, state, {
        fetchNotificationPending: false,
        fetchNotificationSucceeded: true,
        fetchNotificationFailed: false,
        notifications: action.payload.notifications,
        notificationUnreadCounter: action.payload.unreadCounter
      })
    case actionTypes.FETCHNOTIFICATIONFAILED:
      return Object.assign({}, state, {
        fetchNotificationPending: false,
        fetchNotificationSucceeded: false,
        fetchNotificationFailed: true,
        notifications: null,
        notificationUnreadCounter: 0
      })
    case actionTypes.UPDATE_UNREAD_NOTIFICATION_COUNTER:
      return Object.assign({}, state, {
        notificationUnreadCounter: action.payload.unreadCounter
      })
    default:
      return state
  }
}
