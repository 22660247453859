export const initialState = {
  lastUpdate: 0,
  light: false,
  count: 0,
  sendContactPending: false,
  sendContactSucceeded: false,
  sendContactFailed: false,
  fetchCurrentUserPending: false,
  fetchCurrentUserSucceeded: false,
  fetchCurrentUserFailed: false,
  user: null,
  fetchNotificationPending: false,
  fetchNotificationSucceeded: false,
  fetchNotificationFailed: false,
  notifications: null,
  notificationUnreadCounter: 0
}
