import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import thunkMiddleware from 'redux-thunk'
import promise from 'redux-promise-middleware'
import { createLogger } from 'redux-logger'
import { initialState } from './initialState'

// reducers
import { reducers } from './reducers'

const reduxLoggerMiddleware = createLogger({
  // ...options
})

const middlewares = [thunkMiddleware, promise]
// if(process.env.NODE_ENV !== 'production') {
//   middlewares.push(reduxLoggerMiddleware)
// }

const bindMiddlewares = () => {
  if (process && process.env && process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middlewares))
  }
  return applyMiddleware(...middlewares)
}

export function initializeStore(state = initialState) {
  return createStore(reducers, state, bindMiddlewares())
}
